import React, {useContext} from "react"
import styled from "styled-components"
import {  WaveSection } from "@components/common"
import Text from '@typo/Text'
import { FirebaseContext } from '@components/Firebase'
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Icons from "@images/community/icons"
import Header from "@menus/header"
import BackToAppButton from "@common/BackToAppButton"

const StyledNumbers = `
  ol {
    list-style: none; 
    counter-reset: li;
    margin-left:-15px;
  }
  li::before {
    font-family: 'Romana Becker Demi';
    text-align: center;
    content: counter(li)"."; 
    opacity:0.8;
    display: inline-block; 
    width: 1.6rem;
    font-size:1.6rem;
    margin-left: -1.6rem
  }
  li {
    counter-increment: li;
    margin-bottom:10px;
  }
`

const ContentWrapper = styled.div`
  ${StyledNumbers};
  display:flex;
  flex-direction: column;
  align-items: center;
  max-width:800px;
  padding:0 0 50px 0;
  li::before {
    color: #F19D9A; 
  }
`

const ContentWrapperRow = styled.div`
  ${StyledNumbers};
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  width:100%;
  max-width:1200px;
  padding:0%;
  margin: 50px 0 150px 0;
  li::before {
    color: #F19D9A; 
  }
  @media ${props => props.theme.tabletBelow} {
    flex-direction: column;
    margin: 0 0 50px 0;
  }
`

const StyledOl = styled.ol`
  list-style: none; 
  counter-reset: li;
  margin-left:-15px;
  ${StyledNumbers};
  li::before {
    color: #FFF; 
  }
`

const StyledLi = styled.li`
  color:white;
  font-family:${props => props.theme.mainFontHeading};
  font-size:1.5rem;
`

const Title = styled.h1`
  text-align: center;
  font-size:2rem;
  @media ${props => props.theme.tabletBelow} {
    display:flex;
    align-items: center;
    background:${props => props.background ? props.background : 'none' };
    z-index: 5;
    font-size: 2rem;
    padding: 10px;
    height:${props => props.height};
    margin:0;
  }
`

const Section = styled.section`
  display:flex;
  flex-direction: column;
  align-items: center;
  width:100%;
  margin-top:${props => props.mt};
  margin-bottom:${props => props.mb};
  @media ${props => props.theme.tabletBelow} {
    padding:5%; 
  }
`

const BlurbWrapper = styled.div`
  margin: 20px 20px;
  width: 30%;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;
  @media ${props => props.theme.tabletBelow} {
    width: 100%;
  }
`

const BlurbImage = styled.img`
  width:125px;
`
const BlurbText= styled.p`
  text-align:center;
`

const BlurbImageWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 70%;
  max-width: 220px;
`

const AllBlurbsWrapper = styled.div`
  justify-content: center;
  width:100%;
  display: flex;
  flex-wrap: wrap;
  max-width:1200px;
  margin-top:50px;
`

const PageWrapper = styled.div`
  @media (min-width: 768px) {
    width:100%;
    padding-top:150px;
  }
`

const StyledText = styled(Text)`
  @media ${props => props.theme.tabletBelow} {
    margin-top:30px;
  }
  &:first-letter {
    text-transform:${props => props.title ? '' : 'uppercase'};
    font-size:${props => props.title  ? '' : '200%'};
    font-family:${props => props.title ? '' : props.theme.mainFontHeading};
    line-height: 1;
  }
`

const WidthWrapper = styled.div`
  width:${props => props.width};
  display: flex;
  justify-content: center;
  @media ${props => props.theme.tabletBelow} {
    width:100%;
    margin-bottom:${props => props.marginBottom};
  }
`

const CTALink = styled.a`
  text-decoration: none;
  font-family: 'Poppins';
  background-color:${props => props.color ? props.color : 'white'};
  color:${props => props.color ? 'white': '#F19D9A'};
  padding: 10px 60px;
  border-radius: 25px;
  margin:20px 0;
  box-shadow: 0 0px 6px 0 hsla(0, 0%, 0%, 0.2);
  text-transform: uppercase;
  &:hover{
    background-color:${props => props.color ? '#CC736F' : '#ececec'};
    color:${props => props.color ? 'white': '#CC736F'};
  }
`

const Cta = (props) => {

return (
  <CTALink 
    color={props.color}
    href="https://docs.google.com/forms/d/e/1FAIpQLScS1F2_JbkwyG4Wli0AqvtdX-AO8UFUxQvT3WYvI7Uba3ksUA/viewform?usp=sf_link" target="_blank">
    Submit Now
  </CTALink>
)
}



const QuestionWrapper = styled.div`
  margin: 15px 0px;
`

const CommunityVoiceActor = props => {
  const {user} = useContext(FirebaseContext)

  const BlurbContent = [
    "License fee for each accepted story ($150 for exclusive stories, $50 for non-exclusive stories)",
    "Revenue share for users who get a premium subscription using your discount code",
    "Our illustrators will create a beautiful cover art for your stories which you can use to promote your content",
    "A “featured” section to showcase new creators every month",
    "Featured community creators in our newsletter",
    "Ability to add links in your profile (Patreon, Youtube, etc)",
  ]


  const FAQContent = [
    {
      question: "Is non-erotic content be allowed on Audiodesires (such as girlfriend/boyfriend experience audios, affirmations, etc)?",
      answer: `A: Yes, we will not prescribe our form of storytelling for the Community Hub. Affirmations, guided tracks, partner-experience audios are allowed.`,
    },
    {
      question: "Can my content contain copyrighted characters and/or brands?",
      answer: "A: Due to copyright infringements, we cannot accept episodes where copyrighted brands, names, or characters are used. Please avoid using franchise references such as Twilight or Harry Potter, or brand names such as Fleshlight, etc.",
    },
    {
      question: "Can my content be posted on other websites (Reddit, VanillaAudio, Cheex, Quinn, YouTube, Pornhub, etc)?",
      answer: "A: The answer to this depends on whether you opt for the exclusive or non-exclusive licensing model:",
    },
    {
      question: "Does Audiodesires own the copyrights to my content once I upload it?",
      answer: `A: No, as the creator of your content you will always continue to own the copyright of your episode(s). However, by licensing your content to us (exclusively or non-exclusively), Audiodesires obtains worldwide, unlimited, perpetual usage rights.`,
    },
    {
      question: "What kind of files does Audiodesires accept?",
      answer: "A: We prefer broadcast .wav files for all submissions. To ensure your audio is recorded in the best quality, we suggest using mono channel, a sample rate of 48 kHz, and a bit depth of 24-bit (or 32-bit float). We also suggest having at least 20cm of space between your mouth and the microphone and directing your airflow to one side of the mic (on a 15-25 degree off-axis) to avoid unwanted mouth sounds (unless you’re uploading an ASMR episode).",
    },
    {
      question: "Can I track statistics of my content on Audiodesires (story plays, ratings, etc)?",
      answer: "A: Yes! As an Audiodesies Community Creator, you will get access to a Creator Dashboard that provides you with statistics and feedback on your episode(s), like the number of listens, ratings and user reviews, as well as insights on how many users made use of your promo code and affiliate link and how much revenue has been generated in the process. ",
    },
    {
      question: "How does the Community Affiliate program work?",
      answer: "A: Every community creator will receive an affiliate link and unique creator code. Those should be used whenever any Audiodesires content is referenced in bio links, on Reddit, Youtube, etc. Whenever a user clicks your link or uses your creator code to receive a discount on their premium subscription, that user will get attributed to you. For every attributed user who proceeds to purchase a premium subscription, you will earn $3.50 for a monthly subscription and $12.50 for an annual subscription. Please note that we can only compensate you for the initial purchase made by the attributed user, not for potential recurring payments.",
    },
    {
      question: "Do you still have unanswered questions?",
      answer: `Please send us an email with any further questions to community@audiodesires.com and a member of the community team will get back to you as soon as possible.`,
    },
  
  ]

  return (
    <PageWrapper>
      {!user && <Header boxLayout={false} playerMode={'small'} alwaysShow={true}/>}
      {user && <BackToAppButton/>}
      <Section>
        <ContentWrapperRow >
        <WidthWrapper width={"30%"} marginBottom={"-180px"}>
          <GatsbyImage image={props.data.file.childImageSharp.gatsbyImageData}/>
        </WidthWrapper>
        <WidthWrapper  width={"50%"}>
          <ContentWrapper>
          <Title background={"#ffffffad"} position={"absolute"} height={"180px"}>
          Submit your original erotic audio content to our Community Hub!
          </Title>
          
          <StyledText>
          We’re interested in expanding our horizons, starting with our community. That’s why we have created the Community Hub on our platform, where erotic audio artists are able to share their original content with the Audiodesires community and get compensated in the process.
          </StyledText>
          <Text>
            That’s where you come in! The erotic audio community as a whole is very important to us and we want to help you succeed as well as showcase unique, creator-made content on Audiodesires!
          </Text>
          <Cta color="#F19D9A"/>
          </ContentWrapper>
          </WidthWrapper>
        </ContentWrapperRow>
      </Section>  
      
      <WaveSection> 
        <Section>
          <ContentWrapper>
            <Title>What is the Audiodesires Community Hub?</Title>
            <Text>The Community Hub on Audiodesires will showcase episodes created and uploaded by individual erotic audio artists from around the world. In this area, you will be able to feature original audio episodes of your choosing (there are some basic guidelines we ask you to follow in terms of content that is suitable for our audiences). Creators will have access to a dashboard where they can view statistics and feedback on their episodes. </Text>
          </ContentWrapper>
        </Section>
      </WaveSection>
      
      <Section mt={"50px"} mb={"80px"}>
        <ContentWrapper>
          <Title>Audiodesires will provide you with…</Title>
        </ContentWrapper>
          <AllBlurbsWrapper>
            {BlurbContent.map((item, index)=> (
              <BlurbWrapper>
              <BlurbImageWrapper>
              <BlurbImage alt={`Icon`} src={Icons[index]} loading="lazy"/>
              </BlurbImageWrapper>
              <BlurbText>
                {item}
              </BlurbText>
              </BlurbWrapper>
            ))}
          </AllBlurbsWrapper>
      </Section>

      <WaveSection> 
        <Section  >
          <ContentWrapper>
            <Title>How does it work?</Title>
            <Text italic >Audiodesires currently offers two different options for licensing your content to us: exclusive and non-exclusive. In addition, every community creator will receive a revenue share.</Text>
            
            
            <StyledOl>
              <StyledLi>Exclusive Licensing</StyledLi>
              <Text>If you choose the exclusive model, you can submit up to one episode per month to our community hub. These episodes are exclusive to Audiodesires and cannot appear in full anywhere else online. Audiodesires offers $150 per exclusive audio story.</Text>
              <StyledLi>Non-Exclusive Licensing </StyledLi>
              <Text>If you choose the non-exclusive model, you can submit up to one episode per month to our community hub. These episodes can appear on other services and websites, as long as they are locked behind a paywall (Patreon, Onlyfans, Quinn, VanillaAudio, etc.). However, the episode cannot appear in full, for free anywhere online (Reddit, YouTube, Pornhub, etc.). We have made this decision to ensure our listeners receive the most value for their subscription by not serving them content that they could be getting elsewhere for free. Audiodesires offers $50 per non-exclusive audio story.</Text>
              <StyledLi>Revenue Share</StyledLi>
              <Text>In addition to the licensing fee, every community creator receives an affiliate link and unique creator code. Whenever a user clicks your link or uses your creator code to receive a discount on their premium subscription, that user will get attributed to you. For every attributed user who proceeds to purchase a premium subscription, you will earn $3.50 for a monthly subscription and $12.50 for an annual subscription. Please note that we can only compensate you for the initial purchase made by the attributed user, not for potential recurring payments. </Text>
            </StyledOl>
            <Cta/>
          </ContentWrapper>
        </Section>
      </WaveSection>

      <Section mt={"50px"} mb={"80px"}>
        <ContentWrapper>
          <Title>Do you have guidelines for the content that can be submitted?</Title>
          <Text>Here at Audiodesires, we want to give you as much creative freedom as possible while still maintaining our own content standards. </Text>
          <Text>If your episode contains derogatory content, we advise placing a content warning within the episode and reserve the right to promote it with a content warning attached. More information on this can be found in our submission form. </Text>
          <Text>Topics that are not acceptable for Audiodesires include:</Text>
          <ol>
            <li>Incest (including step-siblings, step-parents, etc)</li>
            <li>Nonconsensual activities (rape, forced encounters, CNC, etc)</li>
            <li>Anything involving a minor. All characters & voice actors must be 18+</li>
            <li>Blood (except for in vampire/sci-fi stories where needed) or feces</li>
            <li>Anything with defamatory language (racism, sexism, etc)</li>
          </ol>
        </ContentWrapper>
      </Section>

      <WaveSection> 
        <Section >
          <ContentWrapper>
            <Title>I am interested...now what?</Title>
            <Text>If you are interested in submitting content to Audiodesires Community Hub, please fill in this form or send an email with any further questions to community@audiodesires.com.</Text>
            <Text>Once you submit your first episode to us, this is the typical process:</Text>
            <StyledOl>
              <li>Our community moderators will review the content to ensure it follows our guidelines and passes our audio quality check.</li>
              <li>If your episode(s) are accepted, payment is issued to the creator via bank transfer or PayPal.</li>
              <li>Our illustrators will create cover art for the episode(s) and prepare to feature it on our platform under your artist name.</li>
              <li>Each creator is given a profile page on our platform to showcase their social links and their work.</li>
              <li>We will provide you with promotional material (audio, video and graphical assets) to promote your episode(s) on Audiodesires using your affiliate link and unique creator code which offers your fans a discount for our service and enables a revenue share for you.</li>
              <li>You will have access to a Creator Dashboard that provides you with statistics and feedback on your episode(s), like the number of listens, ratings, and user reviews, as well as insights on how many users made use of your promo code and affiliate link and how much revenue has been generated in the process. </li>
            </StyledOl>
            <Cta></Cta>
          </ContentWrapper>
        </Section>
      </WaveSection>

      <Section mt={"50px"}>
      <ContentWrapper>
        <Title>Community FAQs</Title>
      {FAQContent.map((faq, index) => (
        
        <QuestionWrapper key={index}>
          <Text weight={"bold"}  dangerouslySetInnerHTML={{ __html: faq.question}}></Text>
          <Text italic dangerouslySetInnerHTML={{ __html: faq.answer}}></Text>
          {index === 2 && 
          <ol>
            <li>Episode(s) that are licensed to us exclusively, cannot appear in full anywhere else online.</li>
            <li>Episode(s) that are licensed to us non-exclusively, can appear on other services and websites, as long as they are locked behind a paywall (Patreon, Onlyfans, Quinn, VanillaAudio, etc.). However, the episode(s) cannot appear in full, for free anywhere online (Reddit, YouTube, Pornhub, etc.). We have made this decision to ensure our listeners receive the most value for their subscription by not serving them content that they could be getting elsewhere for free.</li>
            <li>You are able to upload samples (up to 50% of the audio) of each episode to any other platform, regardless of whether it’s free or behind a paywall.</li>
          </ol>
         }
        </QuestionWrapper>
      ))}
      <Cta color="#F19D9A"></Cta>
      </ContentWrapper>
      </Section>
    </PageWrapper>
  )
}

export default CommunityVoiceActor

export const query = graphql`
  query {
    file(relativePath: { eq: "community/community-listen.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
          placeholder: BLURRED,
          width: 400, 
          height: 400, 
          layout: CONSTRAINED)
      }
    }
  }
  `