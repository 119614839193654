
import Icon1 from'./icon-1.svg'
import Icon2 from'./icon-2.svg'
import Icon3 from'./icon-3.svg'
import Icon4 from'./icon-4.svg'
import Icon5 from'./icon-5.svg' 
import Icon6 from'./icon-6.svg' 

const Icons = [
  Icon3,
  Icon2,
  Icon1,
  Icon4,
  Icon5,
  Icon6,
];

export default Icons